import styled, { css } from 'styled-components'
import { Container, Grid, Typography } from '@material-ui/core'
import React, { ComponentProps, ReactChild } from 'react'
import { StyledAlert } from '../AlertNotice/alert'
import { rem } from '../../utils'

const removeMargin = css`
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6,
  > p,
  > figure,
  > ol,
  > ul,
  > img {
    &:first-child {
      margin-block-start: 0;
    }

    &:last-child {
      margin-block-end: 0;
    }
  }
`

const removeMarginsRowStyles = css`
  p {
    margin-block-start: 0;
  }

  ${removeMargin}
  .MuiGrid-item {
    padding-top: 0;
    padding-bottom: 0;

    + .MuiGrid-item {
      margin-block-start: 2.5em;
    }

    ${props => props.theme.breakpoints.keys.map((key) => css`
      &[class*="MuiGrid-grid-${key}"]:last-child {
        ${removeMargin}
      }
      ${props => props.theme.breakpoints.up(key)} {
        ${[12, 10, 9, 8, 7, 6, 5, 4, 3, 2].map((num) => css`
          &[class*="MuiGrid-grid-${key}-${num}"] {
            ${removeMargin}
            ${num !== 12 && css`
              + .MuiGrid-item {
                margin-block-start: 0;
              }
            `}
          }
        `)}
      }
    `)}
  }
`

export const StyledFlexibleContentRow = styled(Container)<{ $keepMargins?: boolean }>`
  div[class*="accordion__StyledAccordionPanel"] {
    margin-bottom: ${props => rem(14, props.theme)};
  }

  .MuiGrid-container {
    margin-top: 0;
    margin-bottom: 0;

    p:last-child {
      margin-bottom: 0;
    }
  }

  ${StyledAlert} {
    max-width: ${props => rem(932, props.theme)};
    padding: ${props => rem(24, props.theme)} ${props => rem(30, props.theme)};

    ${props => props.theme.breakpoints.down('xs')} {
      font-size: ${props => rem(14, props.theme)};
      line-height: ${props => rem(25, props.theme)};
    }

    ${props => props.theme.breakpoints.up('sm')} {
      padding: ${props => rem(32, props.theme)} ${props => rem(57, props.theme)};
    }

    .MuiAlert-message {
      padding: 0;
    }
  }

  ${props => props.$keepMargins ? null : removeMarginsRowStyles}
`

export type FlexibleContentRowProps = Omit<ComponentProps<typeof StyledFlexibleContentRow>, '$keepMargins'> & {
  fullWidth?: boolean
  keepMargins?: boolean
}

export const FlexibleContentRow: React.FC<FlexibleContentRowProps> = ({ fullWidth, keepMargins, children, ...props }) => (
  <StyledFlexibleContentRow
    maxWidth={fullWidth ? false : 'lg'}
    disableGutters={fullWidth}
    // @ts-ignore
    component={'section'}
    $keepMargins={keepMargins}
    {...props}
  >
    {fullWidth ? (children as ReactChild) : (
      <Grid container justifyContent={'center'}>
        <Typography variant="body2" component={Grid} item xs={12}>
          {children}
        </Typography>
      </Grid>
    )}
  </StyledFlexibleContentRow>
)